<template>


    <b-row>

   


     <b-col
        sm="12"
        md="12"
        lg="12"
        xl="12"
        align="center"
      >

             <h4 class="font-weight-bolder">
              Proceso de verificación de usuario
            </h4>
            <hr class="mb-2">

            <b-img-lazy style=" width: 300px !important;" v-bind="mainProps" :src="imageFinal"></b-img-lazy>

            <p style="text-align: center; font-size:15px; ">Verifica tu cuenta para desbloquear todas las funcionalidades de EQCoop</p>
 
            <b-button
          id="btn_verificar"
          ref="btn_verificar"
          @click="verificar"
          variant="primary"
          :disabled="isDisabled"
          
        >

         Verificar mi cuenta

        </b-button>


      </b-col>



    </b-row>


</template>


<script>
import {
  BButton, BAlert, BRow, BCol, BImgLazy
} from 'bootstrap-vue'


export default {
  components: {
    BAlert,
    BButton,
    BRow,
    BCol,
    BImgLazy
  },
  props: ['userId','status','motivo', 'cedula', 'tokenAuth'],
  data() {
    return {
      pin: '',
      sendCode: '',
      cargar: false,
      isDisabled:false,
      imageFinal:"/img/verificar.svg",
      mainProps: {
                      center: true,
                      fluidGrow: true,
                      blank: true,
                      blankColor: '#bbb',
                      width: 600,
                      height: 400,
             
                    }
    }
  },
  computed: {

  },
  watch: {

  },
  mounted() {



  },
  methods: {



    verificar(){

      this.isDisabled=true;

     
      document.getElementById('btn_verificar').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Verificando cuenta'
    
         
                        this.$https.post('/profile/verifyProfile/', { tokenAuth: this.tokenAuth, userId: this.userId }).then(response => {
                          if (response.data.code == 200) {
                          

                            this.$eventBus.$emit('reiniciarVerificacion')



                          
                          } else {
                            this.isDisabled=false;

                            document.getElementById('btn_verificar').innerHTML = 'Verificar mi cuenta'
    

                            this.$toast.error(response.data.message, {
                              position: 'top-right',
                              timeout: 3010,
                              closeOnClick: true,
                              pauseOnFocusLoss: false,
                              pauseOnHover: true,
                              draggable: true,
                              draggablePercent: 0.4,
                              showCloseButtonOnHover: false,
                              hideProgressBar: true,
                              closeButton: 'button',
                              icon: true,
                              rtl: false,
                            })

                            if (response.data.code == 401) {
                              
                              localStorage.removeItem('userData')

                              

                              // Redirect to login page
                              this.$router.push({ name: 'auth-login' })
                            }
                          }
                        }).catch(error => {
                          this.isDisabled=false;

                          document.getElementById('btn_verificar').innerHTML = 'Verificar mi cuenta'
    

                          this.$toast.error("Ocurrió un error inesperado", {
                            position: 'top-right',
                            timeout: 3010,
                            closeOnClick: true,
                            pauseOnFocusLoss: false,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.4,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: 'button',
                            icon: true,
                            rtl: false,
                          })
                        })



    }

  },
}
</script>

<style lang="scss">




</style>
