<template>
      <b-card 
        v-if="loading"
        align="center"
      >

      <b-row>

        <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
              align="center"
            >
              <h4 class="font-weight-bolder">
                Últimas conexiones
              </h4>
              <hr class="mb-2">

            </b-col>


          </b-row>

          <b-row  class="sesiones">
            
            <b-col cols="2" sm="2" md="2" lg="2" xl="2" style="    padding: 0;display: flex;">

              <b-skeleton style="margin: auto;" type="avatar"></b-skeleton>

           
            </b-col>

             <b-col cols="10" sm="10" md="10" lg="10" xl="10">

              <b-skeleton width="60%"  height="10px"></b-skeleton>
              <b-skeleton width="40%"  height="10px"></b-skeleton>

              <b-skeleton width="20%"  height="10px"></b-skeleton>

            </b-col>
            
            </b-row>

            <b-row  class="sesiones">
            
              <b-col cols="2" sm="2" md="2" lg="2" xl="2" style="    padding: 0;display: flex;">

              <b-skeleton style="margin: auto;" type="avatar"></b-skeleton>

           
            </b-col>

             <b-col cols="10" sm="10" md="10" lg="10" xl="10">

              <b-skeleton width="60%"  height="10px"></b-skeleton>
              <b-skeleton width="40%"  height="10px"></b-skeleton>

              <b-skeleton width="20%"  height="10px"></b-skeleton>

            </b-col>
            
            </b-row>

            <b-row  class="sesiones">
            
              <b-col cols="2" sm="2" md="2" lg="2" xl="2" style="    padding: 0;display: flex;">

              <b-skeleton  style="margin: auto;" type="avatar"></b-skeleton>

           
            </b-col>

             <b-col cols="10" sm="10" md="10" lg="10" xl="10">

              <b-skeleton width="60%"  height="10px"></b-skeleton>
              <b-skeleton width="40%"  height="10px"></b-skeleton>

              <b-skeleton width="20%"  height="10px"></b-skeleton>

            </b-col>
            
            </b-row>

            <b-row  class="sesiones">
            
              <b-col cols="2" sm="2" md="2" lg="2" xl="2" style="    padding: 0;display: flex;">

              <b-skeleton  style="margin: auto;" type="avatar"></b-skeleton>

           
            </b-col>

             <b-col cols="10" sm="10" md="10" lg="10" xl="10">

              <b-skeleton width="60%"  height="10px"></b-skeleton>
              <b-skeleton width="40%"  height="10px"></b-skeleton>

              <b-skeleton width="20%"  height="10px"></b-skeleton>

            </b-col>
            
            </b-row>

            <b-row  class="sesiones">
            
              <b-col cols="2" sm="2" md="2" lg="2" xl="2" style="    padding: 0;display: flex;">

              <b-skeleton style="margin: auto;" type="avatar"></b-skeleton>

           
            </b-col>

             <b-col cols="10" sm="10" md="10" lg="10" xl="10">

              <b-skeleton width="60%"  height="10px"></b-skeleton>
              <b-skeleton width="40%"  height="10px"></b-skeleton>

              <b-skeleton width="20%"  height="10px"></b-skeleton>

            </b-col>
            
            </b-row>

            

        </b-card>


        <b-card  v-else-if="code200">

           <b-row>

                <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
              align="center"
            >
              <h4 class="font-weight-bolder">
                Últimas conexiones
              </h4>
              <hr class="mb-2">

            </b-col>

   

            </b-row>

          <b-row  v-for="sesion in sessions" :key="sesion.id"  class="sesiones">
            
            <b-col cols="1" sm="1" md="1" lg="1" xl="1" style="    padding: 0;display: flex;">

              <b-avatar
                        size="40"
                        variant="light-primary"
                        style="cursor:pointer; margin:auto">

                        <span class="d-flex align-items-center">
                        
                          <feather-icon
                         v-if="sesion.typeDevice === 'PC'"
                                          icon="AirplayIcon"
                                          size="20"
                                          class="color_icon"
                                        
                                          /> 

                                           
                       <feather-icon
                         v-else
                                          icon="TabletIcon"
                                          size="20"
                                          class="color_icon"
                                      
                                          /> 

                          
                        </span>

                        </b-avatar>


             

           
            </b-col>

             <b-col cols="10" sm="10" md="10" lg="10" xl="10">

            <p class="sesiones_p">{{sesion.os}} {{sesion.versionOs}} - {{sesion.city}}, {{sesion.country}}</p>
             <p style="margin: 0;font-size: 10px;color:#888888">{{sesion.browser}} {{sesion.versionBrower}} - {{sesion.date}}</p>

              <b-badge style="margin:auto; font-size: 10px;" v-if="sesion.status === 'Activo'" variant="success">
                                        {{sesion.status}}
                                      </b-badge>


                                       <b-badge style="margin:auto; font-size: 10px;" v-else variant="danger">
                                          {{sesion.status}}
                                      </b-badge>

            </b-col>

   

             <b-col cols="1" sm="1" md="1" lg="1" xl="1" v-if="sesion.status === 'Activo' && sesion.id != idActivo">

                
                                         <feather-icon
                                         v-if="isMobile"
                                          icon="MoreVerticalIcon"
                                          size="25"
                                          @click="modalMobileSesion(sesion.origin,sesion.nameDevice,sesion.longlat,sesion.ip,sesion.architecture,sesion.id,sesion.typeDevice, sesion.status, sesion.os,sesion.versionOs,sesion.city, sesion.country,sesion.browser, sesion.versionBrower,sesion.date)" 
                                          class="MoreVerticalIcon"
                                          />

                                          <feather-icon
                                         v-else
                                          icon="MoreVerticalIcon"
                                          size="25"
                                          @click="modalSesion(sesion.origin,sesion.nameDevice,sesion.longlat,sesion.ip,sesion.architecture,sesion.id,sesion.typeDevice, sesion.status, sesion.os,sesion.versionOs,sesion.city, sesion.country,sesion.browser, sesion.versionBrower,sesion.date)" 
                                          class="MoreVerticalIcon"
                                          />

             </b-col>


         
            
            
            </b-row>

        <vue-bottom-dialog  v-if="isMobile" :indentColor="indentColor" :topRadius="radius" v-model="dialog" :height="200"  :overlayColor="overlay">

          <b-row>

            <b-col cols="12" sm="12" md="12" lg="12" xl="12"> 

              <h4 style="margin: 0;" class="font-weight-bolder">
                Cierre de sesión
                <hr>

              </h4>

              </b-col>

              <b-form
            autocomplete="off"
            @submit.prevent="onSubmit2"
            align="center"
          >

            <b-row>

           

              <b-col
                sm="12"
                md="12"
                lg="12"
                xl="12"
              >

                <p
                  class="textLabel"
                  style="text-align: center; color:white !important"
                >
                  Ingrese su código PIN (*): <feather-icon
                icon="HelpCircleIcon"
                size="16"
                  v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                title="Código PIN de seguridad"
                style="margin-right: 5px;cursor:pointer;"
                /> </p>

                <PincodeInput
                  v-model="pin2"
                  placeholder="-"
                  :length="6"
                  :autofocus="false"
                  :secure="true"
                  :readonly="isActive"
                  required
                />

              </b-col>

              <b-col
                sm="12"
                md="12"
                lg="12"
                xl="12"
              >

                <b-button
                  id="btn_close2"
                  ref="btn_close2"
                  type="submit"
                  variant="primary"
                  :disabled="isActive"
                >

                  Cerrar sesión

                </b-button>

              </b-col>

            </b-row>

          </b-form>
            
          </b-row>
                


                </vue-bottom-dialog>

      <b-modal
      v-if="!isMobile"
        ref="modalSesiones"
        centered
        hide-footer
        title="Cerrar sesión"
      >
        <div class="d-block text-center">

          <b-form
            autocomplete="off"
            @submit.prevent="onSubmit1"
          >

            <b-row>

           

              <b-col
                sm="12"
                md="12"
                lg="12"
                xl="12"
              >

                <p
                  class="textLabel"
                  style="text-align: center;"
                >
                  Ingrese su código PIN (*): <feather-icon
                icon="HelpCircleIcon"
                size="16"
                  v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                title="Código PIN de seguridad"
                style="margin-right: 5px;cursor:pointer;"
                /> </p>

                <PincodeInput
                  v-model="pin"
                  placeholder="-"
                  :length="6"
                  :autofocus="false"
                  :secure="true"
                  :readonly="isActive"
                  required
                />

              </b-col>

              <b-col
                sm="12"
                md="12"
                lg="12"
                xl="12"
               
              >

                <b-button
                  id="btn_close"
                  ref="btn_close"
                  type="submit"
                  variant="primary"
                  :disabled="isActive"
                >

                  Cerrar sesión

                </b-button>

              </b-col>

            </b-row>

          </b-form>

        </div>

      </b-modal>

 

        

         </b-card>


       <b-card v-else align="center">

        <feather-icon
             icon="FrownIcon"
             size="50"
             />

        <p class="errorTexto">
          Ocurrió un error al cargar los datos
        </p>

        <b-button
          id="btn_refresh"
          ref="btn_refresh"
          variant="primary"
          @click="refreshSesiones"
        >

          Intentar de nuevo

        </b-button>

         </b-card>




</template>

<script>
import {
  BButton, BForm,BBadge,BAvatar, BFormInput, VBPopover, BFormGroup, BFormSelect, BSkeleton, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import PincodeInput from 'vue-pincode-input'

export default {
  components: {
    PincodeInput,
    BFormSelect,
    BButton,
    BBadge,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BAvatar,
    BInputGroup,
    BInputGroupAppend,
    
    BSkeleton,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: ['userId', 'tokenAuth'],
  data() {

   

    return {
      code200: false,
      loading: true,
      activarCampo: false,
      isActive:false,
      pin2:"",
      idActivo:"",
      idSesionEliminar:"",
      pin: '',
      body: document.getElementsByTagName('body')[0],
      sessions:[],
      isMobile:this.$isMobile,
      dialog:false,
       color: "#242526",
      overlay:"#000000e6",
       radius:"10px",
       indentColor:"#b0b3b8"
    
    }
  },
  computed: {

  },
  watch: {

    dialog(newValue) {


if(newValue){
  this.body.classList.add("overflow-hidden");
}else{
  this.body.classList.remove("overflow-hidden");
}



},

  },
  mounted() {

    const userId_json = { userId: this.userId }
    const user_string = JSON.stringify(userId_json)



    const dataEncripted = this.$encryptBackoffice.encrypt(user_string)

    this.$https.post('/profile/getSessions/', { tokenAuth: this.tokenAuth, userId: dataEncripted }).then(response => {


      if (response.data.code == 200) {
        this.loading = false

       this.sessions= response.data.datos;
       this.idActivo= response.data.idActivo;



        this.code200 = true
      } else {
        this.$toast.error(response.data.message, {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })

        if (response.data.code == 401) {
          
          localStorage.removeItem('userData')



          
          

           

          
          

          // Redirect to login page
          this.$router.push({ name: 'auth-login' })
        } else {
          this.code200 = false
        }
      }
    }).catch(error => {
      this.$toast.error("Ocurrió un error inesperado", {
        position: 'top-right',
        timeout: 3010,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.4,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false,
      })
    })
  },
  methods: {

    refreshSesiones() {
      this.$eventBus.$emit('reiniciarSesiones')
    },
    modalMobileSesion(origin,nameDevice,longlat,ip,architecture,id,typeDevice, status, os,versionOs,city, country,browser, versionBrower,date){

      this.dialog=true;
       this.idSesionEliminar =id;

    },
    modalSesion(origin,nameDevice,longlat,ip,architecture,id,typeDevice, status, os,versionOs,city, country,browser, versionBrower,date ){
      this.$refs.modalSesiones.show()
      this.idSesionEliminar =id;

    },

     onSubmit2(event) {
      event.preventDefault()

      const data_json = {
        userId: this.userId, pin: this.pin2, id: this.idSesionEliminar
      }
      const data_string = JSON.stringify(data_json)




      const dataEncripted = this.$encryptBackoffice.encrypt(data_string)

      this.isActive = true

      document.getElementById('btn_close2').innerHTML = '<img style="margin-left:10px; width: 15px " src="/img/loading2.svg" alt="Loading" />Cerrando sesión, espere'

      this.$https.post('/acceso/closeSesion/', { tokenAuth: this.tokenAuth, datos: dataEncripted }).then(response => {
        document.getElementById('btn_close2').innerHTML = 'Cerrar sesión'
        this.isActive = false



        if (response.data.code == 200) {

          this.refreshSesiones();

            this.dialog=false;
          this.pin2 = ''
          this.$toast.success(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })
        } else {
          this.$toast.error(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })

          if (response.data.code == 401) {
            
            localStorage.removeItem('userData')



            
            

           

            
            

            // Redirect to login page
            this.$router.push({ name: 'auth-login' })
          } else if (response.data.code == 503) {
            this.pin = ''
          }
        }
      }).catch(error => {
        this.$toast.error("Ocurrió un error inesperado", {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })
      })
    },

    onSubmit1(event) {
      event.preventDefault()

      const data_json = {
        userId: this.userId, pin: this.pin, id: this.idSesionEliminar
      }
      const data_string = JSON.stringify(data_json)


      const dataEncripted = this.$encryptBackoffice.encrypt(data_string)

      this.isActive = true

      document.getElementById('btn_close').innerHTML = '<img style="margin-left:10px; width: 30px " src="/img/loading2.svg" alt="Loading" />Cerrando sesión, espere'

      this.$https.post('/acceso/closeSesion/', { tokenAuth: this.tokenAuth, datos: dataEncripted }).then(response => {
        document.getElementById('btn_close').innerHTML = 'Cerrar sesión'
        this.isActive = false

        if (response.data.code == 200) {

          this.refreshSesiones();

           this.$refs.modalSesiones.hide()
          this.pin = ''
          this.$toast.success(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })
        } else {
          this.$toast.error(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })

          if (response.data.code == 401) {
            
            localStorage.removeItem('userData')



            
            

           

            
            

            // Redirect to login page
            this.$router.push({ name: 'auth-login' })
          } else if (response.data.code == 503) {
            this.pin = ''
          }
        }
      }).catch(error => {
        this.$toast.error("Ocurrió un error inesperado", {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })
      })
    }

  },
}
</script>

<style lang="scss">




</style>
