<template>


  <b-card
        v-if="loading"
        align="center"
      >

      <b-row>

<b-col
cols="3"
    sm="3"
    md="3"
    lg="3"
    xl="3"
  ></b-col>

  <b-col
  cols="6"
    sm="6"
    md="6"
    lg="6"
    xl="6"
  >
       
  <b-skeleton width="100%"  height="20px"></b-skeleton>


</b-col>

  <b-col
  cols="3"
    sm="3"
    md="3"
    lg="3"
    xl="3"
  ></b-col>

  <b-col
    sm="12"
    md="12"
    lg="12"
    xl="12"
  > <hr style="margin:5px"></b-col>

  <b-col cols="4" sm="4" md="4" lg="4" xl="4">



</b-col>

  <b-col cols="4" sm="4" md="4" lg="4" xl="4" align="center">

<b-skeleton type="avatar" width="100px" height="100px"></b-skeleton>


</b-col>

<b-col cols="4" sm="4" md="4" lg="4" xl="4">



</b-col>

<b-col cols="12" sm="12" md="12" lg="12" xl="12" style="margin-top: 15px;">

  <b-skeleton width="100%"  height="40px"></b-skeleton>

</b-col>




  </b-row>

        
      </b-card>



       
     <b-card v-else-if="code200">

        <b-row 
        v-if="isProfileVerificated"

                align="center"
              >

    

              <b-col cols="12" sm="12" md="12" lg="12" xl="12"  style="text-align: center;">

                <h4 class="font-weight-bolder">
                        Perfil verificado
                  </h4>
                  <hr class="mb-2">

          


              </b-col>

              <b-col cols="12" sm="12" md="12" lg="12" xl="12" >

                     <img

                      alt="Usuario verificado"
                      src="/img/checkUser.svg"
                      style="width: 115px;"
                      >

                    <b-alert
                    variant="primary"
                    show
                    style="margin-top: 10px;"
                    >
                    <div class="alert-body" style="text-align: center;">
                    <p style="text-align: center; font-size:14px; line-height: 25px;">Estimado {{displayName}}, su perfil de usuario ya se encuentra verificado y puede hacer uso de todas las funcionalidades de EQCoop.</p>

                    </div>
                    </b-alert>

            


              </b-col>

             
             

           

              </b-row>

              <div
                v-else-if="hasDni"
                align="center"
              >

              

             
              <verify
                    :user-id="userId"
                    :status="statusAprobacionCedula"
                    :motivo="motivoRechazoCedula"
                    :cedula="dni"
                    :token-auth="tokenAuth"
                  />
              </div>

             <div
                v-else
                align="center"
              >
              
                      <img

                      alt="Usuario no verificado"
                      src="/img/dni.svg"
                    >

                <p style="text-align: center; font-size:16px; line-height: 25px;margin-top: 20px;">Para verificar su cuenta debe tener vinculado un número de cédula a su perfil.</p>
              

                <b-button
          id="btn_refresh"
          ref="btn_refresh"
          variant="primary"
          :to='{ name: "perfil", hash: "#general"}'
        >

          Registrar cédula/DNI

        </b-button>
              
      
              </div>

  </b-card>



        <b-card align="center"  v-else>

         <feather-icon
             icon="FrownIcon"
             size="50"
             />
        <p class="errorTexto">
          Ocurrió un error al cargar los datos
        </p>

        <b-button
          id="btn_refresh"
          ref="btn_refresh"
          variant="primary"
          @click="refreshVerificacion"
        >

          Intentar de nuevo

        </b-button>

          </b-card>


</template>

<script>
import {
  BButton,BSkeleton, BRow, BCol, BCard, BAlert, BAvatar
} from 'bootstrap-vue'

import Verify from './Verify.vue'
import Vue from 'vue'


export default {
  components: {
    BButton,
    Verify,
    BAvatar,
    BAlert,
    BSkeleton,
    BRow,
    BCol,
    BCard,
    
  },
  props: ['userId', 'tokenAuth'],
  data() {



    return {
      loading: true,
      code200:false,
      isProfileVerificated: false,
      hasDni: false,
      statusAprobacionCedula:"",
      motivoRechazoCedula:"",
      dni:"",
      displayName:"",

    }
  },
  computed: {

  },
  watch: {



  },
  mounted() {

 

     const userId_json = { userId: this.userId }
    const user_string = JSON.stringify(userId_json)

    const dataEncripted = this.$encryptBackoffice.encrypt(user_string)

    this.$https.post('/profile/checkVerify/', { tokenAuth: this.tokenAuth, userId: dataEncripted }).then(response => {
      if (response.data.code == 200) {
        this.loading = false
         this.code200 = true

         this.isProfileVerificated= response.data.isProfileVerificated;
           this.hasDni= response.data.hasDNI;
            this.dni= response.data.dni;
           this.statusAprobacionCedula= response.data.statusAprobacionCedula;
           this.motivoRechazoCedula=response.data.motivoRechazoCedula;

           if(this.isProfileVerificated){



                 const datos_json = JSON.parse(this.$userGlobal);

                datos_json.isProfileVerificated = this.isProfileVerificated
                datos_json.isProfileComplete = true
                this.displayName=datos_json.displayName;
                Vue.prototype.$userGlobal= JSON.stringify(datos_json);


                 localStorage.setItem('userData', JSON.stringify(datos_json))

           }



       
      } else {
        this.$toast.error(response.data.message, {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })

        if (response.data.code == 401) {
          
          localStorage.removeItem('userData')



          
          

           

          
          

          // Redirect to login page
          this.$router.push({ name: 'auth-login' })
        } else {
          this.code200 = false
        }
      }
    }).catch(error => {
      this.$toast.error("Ocurrió un error inesperado", {
        position: 'top-right',
        timeout: 3010,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.4,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false,
      })
    })

  },
  methods: {

        refreshVerificacion() {
      this.$eventBus.$emit('reiniciarVerificacion')
    },

    verifyStatusProfil(event){

      event.preventDefault();

      const userId_json = { userId: this.userId, pin: this.pinVerificar, idCard: this.idCard, mount: this.mount }
      const user_string = JSON.stringify(userId_json);


      this.isActive2 = true

            const dataEncripted = this.$encryptBackoffice.encrypt(user_string)

      document.getElementById('btn_verificar').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Verificando, espere'

      this.$https.post('/card/verifyCard/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
        this.isActive2 = false

        document.getElementById('btn_verificar').innerHTML = 'Verificar tarjeta';

     
        if (response.data.code == 200) {

   
          this.pinVerificar= "";
          this.mount="";
          this.idCard="";

               this.$toast.success(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })
               
 
            

          this.$refs.modalVerificar.hide();

              this.$eventBus.$emit('reiniciarListado');

              
              if(Number(response.data.eqpoints) > Number("0")){

            
              this.eqcoins(response.data.eqpoints, "asociar una nueva tarjeta de pago a tu perfil", "");


              }


        } else {

            this.$toast.error(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })


          if (response.data.code == 401) {
            
            localStorage.removeItem('userData')



            
            

           

            
            

            // Redirect to login page
            this.$router.push({ name: 'auth-login' })
          }else{

             if (response.data.code == 504) {

                  if(response.data.intentosVerificacion <= 0){

               
                        this.pinVerificar= "";
                        this.mount="";
                        this.idCard="";

                        this.$refs.modalVerificar.hide();

                    }else{

                        this.intentos=response.data.intentosVerificacion;


                    }

             }

          }
        }
      }).catch(error => {
        this.$toast.error("Ocurrió un error inesperado", {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })
      })
      

    },


  },
}
</script>
