<template>
  <b-card>

       <b-modal
      ref="modalVerificar"
      centered
      hide-footer
      title="Verificación de tarjeta de pago"
    >
      <div class="d-block text-center">

       

          <b-form autocomplete="off"  @submit.prevent="verifyCard">
            <b-row>
           

              <b-col
                          sm="12"
                          md="12"
                          lg="12"
                          xl="12"
                          align="center"
                        >

                                <p style="text-align: center;margin-bottom: 5px;font-weight: bold;">Ingrese el monto cobrado a su tarjeta: 

                                <feather-icon
                                  icon="HelpCircleIcon"
                                  size="16"
                                  v-b-popover.hover.bottom.html="'Para saber el monto cobrado, revisa los pagos salientes de tu tarjeta en la fecha y hora en la que hizo este pago. Recuerda que es un monto menor a $1.00 USD'"
                                  title="Monto cobrado"
                                  style="margin-right: 5px;cursor:pointer;"
                                  /> </p>
                                
                                
                         

           </b-col>
           


             <b-col
                          sm="12"
                          md="12"
                          lg="12"
                          xl="12"
                          align="center"
                        >

                        <AutoNumericVue
                        v-model="mount"
                        required
                        type="text" inputmode="decimal"
                        @blur="onBlurMount"
                        :autofocus="false"
                        :readonly="isActive2"
                        class="autonumeric_input"
                        :options="json_opciones"
                        :placeholder="'$0.00'"
                    ></AutoNumericVue>
                    <p style="margin-bottom:10px; text-align: center;font-size:12px; font-style:italic">El valor ingresado debe ser en dólares americanos, caso contrario, realizar la conversión de su moneda local a USD. </p>

           </b-col>

           <b-col
                                    sm="12"
                                    md="12"
                                    lg="12"
                                    xl="12"
                                    align="center"
                                  >

                                  <b-alert
                          variant="primary"
                          show
                        >
                          <div class="alert-body">
                      

                                             
                            <p style="text-align: center;margin-bottom: 0px;margin-top:10px">Número de intentos restantes: <b style="font-size: 20px;margin-right: 5px;"> {{intentos}} intentos</b>
              

              <feather-icon
                              icon="HelpCircleIcon"
                              size="18"
                               v-b-popover.hover.bottom.html="'Dispone de solo 2 intentos para ingresar el monto cobrado, en el caso de fallar los 2 intentos se procederá a eliminar la tarjeta registrada.'"
                                title="Intentos de verificación"
                              style="margin-right: 5px;cursor:pointer;"
                              /> </p>
                      
                          </div>
                        </b-alert>


                    </b-col>


           
                <b-col
                          sm="12"
                          md="12"
                          lg="3"
                          xl="3"
                          align="center"
                        >

           </b-col>



          
                        <b-col
                          sm="12"
                          md="12"
                          lg="6"
                          xl="6"
                          align="center"
                        >

                          <b-button
                            id="btn_verificar"
                            ref="btn_verificar"
                            style="margin-top:15px; margin-bottom: 15px;"
                            type="submit"
                            variant="primary"
                            :disabled="isActive2"
                            block
                          >

                            Verificar tarjeta
                          </b-button>

                        </b-col>

                             <b-col
                          sm="12"
                          md="12"
                          lg="3"
                          xl="3"
                          align="center"
                        >

                     </b-col>

                     
                      <b-col
                          sm="12"
                          md="12"
                          lg="12"
                          xl="12"
                          align="center"
                          
                        >

                        <b-alert
                          variant="primary"
                          show
                        >
                          <div class="alert-body">
                            <span> En el caso de aún no disponer del monto cobrado puede ingresarlo despúes desde el menú <b>Mi Perfil > Mis tarjetas de pago > Todas mis tarjetas</b> para verificar la tarjeta registrada.   </span>
                          </div>
                        </b-alert>

                          </b-col>


          </b-row>

           </b-form>

      </div>

    </b-modal>


        <b-tabs
        class="row"
          content-class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-1 mt-md-0"
          pills
          nav-wrapper-class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          nav-class="nav-left"

         align="center"
        >



          <b-tab active>

            <template #title>

              
                  <feather-icon
                                          icon="CreditCardIcon"
                                          size="20"

                                          class="margin-icon-profile"
                                          />

      
              <span class=" d-none d-sm-none d-md-block d-lg-block d-xl-block"> Agregar nueva tarjeta</span>
              <span class="titulo_menu  d-block d-sm-block d-md-none d-lg-none d-xl-none">Agregar tarjeta</span>
            </template>



                   <b-form
                     v-if="isProfileVerificated"
                      autocomplete="off"
               
               
                    >

                      <b-row>

                

                 

     
                        <b-col
                        cols="12"
                          sm="12"
                          md="12"
                          lg="6"
                          xl="6"
                        >
                          <p class="textLabel">
                            Datos de la tarjeta (*):
                          </p>
                          <inline-credit-card-field
                            :key="componentKey"
                            v-model="card"
                            :disabled="activarCampo"
                            size="md"
                            required
                            @invalid="resultsInvalid = $event"
                            @valid="results = $event"
                          />

                        </b-col>

           

                        <b-col
                        cols="12"
                          sm="12"
                          md="12"
                          lg="6"
                          xl="6"
                        >

                          <p
                            class="textLabel"
                            style="text-align: center;"
                          >
                            Ingrese su código PIN (*): <feather-icon
                                  icon="HelpCircleIcon"
                                  size="16"
                                    v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                                  title="Código PIN de seguridad"
                                  style="margin-right: 5px;cursor:pointer;"
                                  /> </p>

                          <PincodeInput
                            v-model="pin"
                            required
                            placeholder="-"
                            :length="6"
                            :autofocus="false"
                            :secure="true"
                            :readonly="activarCampo"
                          />

                        </b-col>

                        <b-col
                          sm="12"
                          md="12"
                          lg="3"
                          xl="3"
                        />

              

                        <b-col
                          sm="12"
                          md="12"
                          lg="6"
                          xl="6"
                          align="center"
                        >

                          <b-button
                            id="btn_registrar"
                            ref="btn_registrar"
                            style="margin-top:15px"
                            variant="primary"
                            :disabled="activarCampo"
                            block
                            @click="verifyPay"
                          >

                            Registrar tarjeta
                          </b-button>

                        </b-col>

                        <b-col
                          sm="12"
                          md="12"
                          lg="3"
                          xl="3"
                        />

                        <b-col
                          sm="12"
                          md="12"
                          lg="2"
                          xl="2"
                        />

                        <b-col
                          sm="12"
                          md="12"
                          lg="8"
                          xl="8"
                          align="center"
                          style="margin-top: 10px;"
                        >

                          <img
                            alt="PCI"
                            style="margin-right: 10px;"
                            src="/img/pci.svg"
                          >
                          <img
                            alt="VISA"
                            style="margin-right: 10px;"
                            src="/img/visa.svg"
                          >
                          <img
                            alt="Mastercard"
                            style="margin-right: 10px;"
                            src="/img/mastercard.svg"
                          >
                          <img
                            alt="American Express"
                            style="margin-right: 10px;"
                            src="/img/american-express.svg"
                          >

                          <p style="font-size: 10px;line-height: 12px;margin-bottom: 0px;">Los datos de este pago son encriptados y manejados por nuestro sistema de seguridad antifraude. Norma de seguridad PCI-DSS nivel 3.</p>

                        </b-col>

                        <b-col
                          sm="12"
                          md="12"
                          lg="2"
                          xl="2"
                        />

                        

                     

                  

                      </b-row>

                    </b-form>
           

              <b-col
                v-else
                sm="12"
                md="12"
                lg="12"
                xl="12"
                align="center"
              >

              <img

                alt="Usuario no verificado"
                src="/img/verifyAccount.svg"
                >

                <p style="text-align: center; font-size:14px; line-height: 25px;margin-top: 20px;">Para utilizar esta funcionalidad su cuenta debe estar verificada.</p>
                <b-button
                id="btn_refresh"
                ref="btn_refresh"
                variant="primary"
                :to='{ name: "perfil", hash: "#verificacion"}'
                >

                Verificar mi cuenta

                </b-button>
              </b-col>

         

          </b-tab>

          <b-tab @click="listadoTarjetas">

            <template #title>
                        <feather-icon
                                          icon="ListIcon"
                                          size="21"

                                          class="margin-icon-profile"
                                          />
              <span class=" d-none d-sm-none d-md-block d-lg-block d-xl-block">Todas mis tarjetas</span>
              <span class="titulo_menu  d-block d-sm-block d-md-none d-lg-none d-xl-none">Mis tarjetas</span>
            </template>

            <listado-tarjetas
              v-if="ifListado"
              :key="componentKeyListadoTarjetas"
              :user-id="userId"
                 :token-auth="tokenAuth"
            />

          </b-tab>

   

        </b-tabs>



   

  </b-card>
</template>

<script>
import {
  BButton, BForm, VBPopover, BTabs, BAlert, BTab, BRow, BCol, BCard
} from 'bootstrap-vue'
import PincodeInput from 'vue-pincode-input'
import InlineCreditCardField from './InlineCreditCardField'
import ListadoTarjetas from './ListadoTarjetas.vue'
import AutoNumericVue from 'autonumeric-vue/src/components/AutoNumericVue';


export default {
  components: {
    BButton,
    BAlert,
    AutoNumericVue,
    PincodeInput,
    BForm,
    BTab,
    BRow,
    BTabs,
    BCol,
    ListadoTarjetas,
    BCard,
    InlineCreditCardField,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: ['userId','tokenAuth'],
  data() {

const datos_json = JSON.parse(this.$userGlobal);


    return {
      activarCampo: false,
      card: '',
      mount:"",
      pin: '',
      pinVerificar:"",
      idCard:"",
      isActive2:false,
      componentKey: 0,
      json_opciones:{},
      intentos:0,
      ifListado: false,
      componentKeyListadoTarjetas: 0,
      isActive: true,
      resultsInvalid: null,
      results: null,
      cardNumber: '',
      expMonth: '',
      cvc: '',
      expYear: '',
      brand: '',
      isProfileVerificated: datos_json.isProfileVerificated,
      isCardValid:false,

    }
  },
  computed: {

  },
  watch: {

    results(newValue) {

    this.cardNumber = newValue.card.number
      this.expMonth = newValue.card.expMonth
      this.cvc = newValue.card.cvc
      this.expYear = newValue.card.expYear
      this.brand = newValue.card.brand

    


      if (this.cardNumber?.toString().length >= Number('14') && this.expMonth?.toString().length == Number('2') && this.cvc?.toString().length >= Number('3') && this.expYear?.toString().length == Number('2')) {
        this.isCardValid = true
        } else {
          this.isCardValid = false
        }
    },

    resultsInvalid() {
      this.isCardValid = false
    },

  },
  mounted() {

    this.json_opciones={allowDecimalPadding: "false", currencySymbol: "$", decimalCharacterAlternative: ",",decimalCharacter:".",   minimumValue: 0, maximumValue:1, modifyValueOnWheel: false };





    this.$eventBus.$on('reiniciarListadoTarjetas', () => {
      this.componentKeyListadoTarjetas += 1
    })


    this.cargarScript();

       


   
  },
  methods: {

    cargarScript(){

      // As an instance method inside a component
      this.$loadScript("https://js.stripe.com/v3/")
      .then(() => {
      // Script is loaded, do something

      })
      .catch(() => {
      // Failed to fetch script
      this.cargarScript();
      });

      },

    onBlurMount(){

      
    

if(this.mount == null || this.mount == "null" || this.mount ==""){

  if(Number(this.mount) == Number("0")){

        this.mount="";

        this.$toast.error("El monto ingresado debe ser mayor a $0.00 USD", {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })


        }



}
},

    verifyCard(event){

      event.preventDefault();

      const userId_json = { userId: this.userId, pin: this.pinVerificar, idCard: this.idCard, mount: this.mount }
      const user_string = JSON.stringify(userId_json);


      this.isActive2 = true

            const dataEncripted = this.$encryptBackoffice.encrypt(user_string)

      document.getElementById('btn_verificar').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Verificando, espere'

      this.$https.post('/card/verifyCard/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
        this.isActive2 = false

        document.getElementById('btn_verificar').innerHTML = 'Verificar tarjeta';

     
        if (response.data.code == 200) {

   
          this.pinVerificar= "";
          this.mount="";
          this.idCard="";

               this.$toast.success(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })
               
 
            

          this.$refs.modalVerificar.hide();

              this.$eventBus.$emit('reiniciarListadoTarjetas');
              
                if(Number(response.data.eqpoints) > Number("0")){

            
                    this.eqcoins(response.data.eqpoints, "asociar una nueva tarjeta de pago a tu perfil", "");


                    }


        } else {

            this.$toast.error(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })


          if (response.data.code == 401) {
            
            localStorage.removeItem('userData')



            
            

           

            
            

            // Redirect to login page
            this.$router.push({ name: 'auth-login' })
          }else{

             if (response.data.code == 504) {

                  if(response.data.intentosVerificacion <= 0){

               
                        this.pinVerificar= "";
                        this.mount="";
                        this.idCard="";

                        this.$refs.modalVerificar.hide();

                    }else{

                        this.intentos=response.data.intentosVerificacion;


                    }

             }

          }
        }
      }).catch(error => {
        this.$toast.error("Ocurrió un error inesperado", {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })
      })
      

    },

    verifyPay(){

      if(this.isCardValid){

        if(Number(this.pin.toString().length) == Number("6")){

this.$swal({
title: "Verificación de tarjeta",
text: "Para verificar que la tarjeta ingresada es de su pertenencia, EQCoop hará un cargo de un valor menor a $1.00 USD y se le solicitará que ingrese la cantidad cobrada en dólares américanos. Si el monto ingresado es correcto, se procederá con el pago inicial y se reembolsará el dinero cobrado de esta verificación.",
icon: 'warning',
showCancelButton: true,
confirmButtonText: "Confirmar pago",
cancelButtonText: 'Cancelar',
customClass: {
confirmButton: 'btn btn-primary',
cancelButton: 'btn btn-outline-secondary ml-1',
},
buttonsStyling: false,
showLoaderOnConfirm: true,
preConfirm: () => {

const userId_json = { userId: this.userId, pin: this.pin, cardNumber: this.cardNumber, expMonth: this.expMonth, cvc: this.cvc, expYear: this.expYear, brand: this.brand }
const user_string = JSON.stringify(userId_json)





const dataEncripted = this.$encryptBackoffice.encrypt(user_string)

return this.$https.post('/card/addCard/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => response.data).catch(error => {
this.$swal.showValidationMessage(
error,
)
})
},
allowOutsideClick: () => !this.$swal.isLoading(),
}).then(result => {

this.$swal.close()

if(result.isConfirmed){


if (result.value.code == 200) {

this.idCard= result.value.id;
this.pinVerificar= this.pin;
    
  this.pin = ''
  this.componentKey += 1

  this.intentos=2;



this.$refs.modalVerificar.show()

this.$eventBus.$emit('reiniciarListadoTarjetas');


    } else {

      
  

    this.$toast.error(result.value.message, {
    position: 'top-right',
    timeout: 3010,
    closeOnClick: true,
    pauseOnFocusLoss: false,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.4,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: 'button',
    icon: true,
    rtl: false,
    })

    if (result.value.code == 401) {

      
    localStorage.removeItem('userData')

    

    
    

   

    
    

    // Redirect to login page
    this.$router.push({ name: 'auth-login' })

    } else if (result.value.code == 503) {
    this.pin = ''
  }else if(result.value.code == 501){
          this.$swal({
              title: 'Tu perfil debe estar verificado para realizar esta acción',
              text: `¿Deseas verificar tu cuenta ahora?`,
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Verificar cuenta',
              cancelButtonText: 'No',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-secondary ml-1',
              },
              buttonsStyling: false,
            }).then(result => {
              if (result.value) {

                this.$router.push({ name: 'perfil', hash: '#verificacion' })

              }
            })
        }

    }

}





})

}else{


this.$toast.error("El código PIN debe ser de 6 dígitos", {
    position: 'top-right',
    timeout: 3010,
    closeOnClick: true,
    pauseOnFocusLoss: false,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.4,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: 'button',
    icon: true,
    rtl: false,
    })

}

      }else{
        this.$toast.error("Los datos de tu tarjeta están incompletas o erróneas", {
                                      position: 'top-right',
                                      timeout: 3010,
                                      closeOnClick: true,
                                      pauseOnFocusLoss: false,
                                      pauseOnHover: true,
                                      draggable: true,
                                      draggablePercent: 0.4,
                                      showCloseButtonOnHover: false,
                                      hideProgressBar: true,
                                      closeButton: 'button',
                                      icon: true,
                                      rtl: false,
                                      })
      }

      




      },


    isLetter(e) {
      const char = String.fromCharCode(e.keyCode) // Get the character
      if (/^[a-zA-Z\s]*$/g.test(char)) return true // Match with regex
      e.preventDefault() // If not match, don't add to input text
    },

    listadoTarjetas() {
      this.ifListado = true
    },

  },
}
</script>
